import React from 'react';
import Layout from '../components/layout';
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import { Helmet } from 'react-helmet';

function socials() {
    return (
        <Layout>
            <Helmet>
                <script type="text/javascript" src="https://platform.linkedin.com/badges/js/profile.js" async defer></script>
            </Helmet>
            
            <div className="grid-item">
                <div className="grid-content">
                    <div id="socialDiv">
                        <div className="row socialsRow" >
                            <div className="col-md-6">
                                <div className="LI-profile-badge"  data-version="v1" data-size="medium" data-locale="en_US" data-type="vertical" data-theme="dark" data-vanity="garrett-demeyer-b0461477"><a className="LI-simple-link" href='https://www.linkedin.com/in/garrett-demeyer-b0461477?trk=profile-badge'>Garrett DeMeyer</a></div>
                            </div>
                            <div className="col-md-6 twitterProfile">
                                <TwitterTimelineEmbed
                                    sourceType="profile"
                                    screenName="Garrett_DeMeyer"
                                    theme="dark"
                                    options={{height: 30}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default socials;